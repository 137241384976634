import React from 'react'
import banner from '../../assets/images/home_banner_without_text.png'
import './ProjectManagement.css'
import backgnd from '../../assets/images/Icons_and_images/Manage-services-image.jpg'
import cardbg from '../../assets/images/Icons_and_images/get-started-image.jpg'
import icon1 from '../../assets/images/Icons_and_images/manage_multiple_project_hover.png'
import ProjectMgntResuablecomponent from '../../util/ProjectManagement'
import { Link } from 'react-router-dom'
function ProjectMgnt() {
    return (
      <div class="container-fluid">
        <div class="textOverImgcontactus7"
            style={{ backgroundImage: `url(${backgnd})` }} >


            <div class="row divpadst">
               <div class="row">
                <div class="col-lg-5 col-xl-5 pt-5 ">

                    <p className='whitetxt1'>  &lt;&lt;&lt; &nbsp;&nbsp;Manage Your Projects &nbsp;&nbsp; &gt;&gt;&gt;</p>
                    <p className='whitehead2'>Project Management
                    <br></br> with Intelligence</p>

                </div>
                <div class="col-lg-5 col-xl-5 pt-5">
                    <p className='ptxt'>Construction is a general term meaning the art and science
                     to form objects, systems,
                     or organizations and comes from Latin constructio and Old French. </p>
                </div>
                </div>
                <div class="row">
            <div class="col-lg-8 col-xl-8 col-md-8 ">
             
            <div class="row ">    
       <ProjectMgntResuablecomponent />
        </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-3 topbox ">
            <div style={{ backgroundImage: `url(${cardbg})` }}>
          <div class="rounded" className="cardimgtext">
            <font className="getstarted">
            <h6> Get Started</h6>
            </font>
            <div className="cardimgtxt">
           <h3> Try the Best
            <br></br> Management 
            <br></br>tool in the 
            <br></br>industry today! </h3>
            </div>
            <div class="pt-2">  
              {/* <Link to='/Features'
              >
             
                <button type="button " className="bg11 ">
                  <b>Request Demo </b>
                </button>
              </Link> */}
              </div>
          </div>
        </div>
                </div>
                </div>
                </div>
        </div>
        </div>
    )
}
export default ProjectMgnt
