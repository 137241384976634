import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/cup.png'

const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid ">
      <div class="row lightgrayback1  textpadspace1 pb-5 pt-5">
        <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 pt-3">
          <div className="newdivision">
            <ul className="normaltextblacknew biochamppad ">
              <li className="bioalign">
                Biology champion league. Neet exam has 360 worth of biology
                segment can obtain from 90 questions.
              </li>
              <li className="bioalign">
                Using biology champion league, students can regularly practice
                the terminology all possible direct and indirect question.
              </li>
              <li className="bioalign">
                Spending average time of 30 mins per day in biology champion
                league test practice will help you score excellent results in
                this segment. By this practice students get used to the
                terminologies
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-0 col-sm-0 col-0"></div>
        <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12 pt-5">
          <img src={icon} width="100%" />
        </div>
      </div>
    </div>
  )
}

export default Gallery
