import TextonImage from '../Component/TextonImage/TextonImage'
import Projectprogress from '../Component/LeftImageRightText/ProjectProgress';
import ProjectMgnt from '../Component/BlurImageBackground/ProjectManagement';
import Imagetext1 from '../Component/ImageandText/ImageandText';
import Imagetext2 from '../Component/ImageandText/ImageandRightText';
import Career from '../Component/Career/Career';
import WeareHere from '../Component/WeareHere/WeareHere';
import CoreValues from '../Component/CoreValues/CoreValues'
import HomeBanner from '../Component/FirstBanner/Welcometobrick';
import Banner1 from '../Component/Banner/Banner';
import Art from '../Component/Artficial/Artificial';
import Services from '../Component/Services/Services';
import Ais from '../Component/AI/AI';


function HomePage() {
  return (
    <div class="container-fluid pl-0 pr-0">
        {/* <HomeBanner  pagename="Welcome to AIS"/>   */}
        
     {/* <Banner1 /> */}
     <Art/>
     <Services/>
  
     
     {/* <Projectprogress /> */}
     {/* <ProjectMgnt/> */}
     <div className='toppad'>
     <Imagetext1 />
     </div>
     <div className='mt-5'>
     <Imagetext2 />
     </div>
     <CoreValues/>
     
     <Career />
     <div >
     <Ais/>
     </div>
    
    
     

     {/* <WeareHere pagename="Home"/> */}
    </div>
  );
}

export default HomePage;

