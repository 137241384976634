import React from 'react'
import dot from '../../assets/images/servicesimg.png'
import brick_logo from '../../assets/images/Brick letter logo.png'
import sarasu_logo from '../../assets/images/sarasu logo 2.png'
import img1 from '../../assets/images/Icons_and_images/009.png'
import img2 from '../../assets/images/Icons_and_images/006.png'
import img3 from '../../assets/images/Icons_and_images/008.png'
import img4 from '../../assets/images/Icons_and_images/007.png'
import img5 from '../../assets/images/Icons_and_images/005.png'
import img6 from '../../assets/images/Icons_and_images/002.png'
import img7 from '../../assets/images/Icons_and_images/003.png'
import img8 from '../../assets/images/Icons_and_images/001.png'
import img9 from '../../assets/images/Icons_and_images/004.png'
import Web_App from '../../assets/images/mobappicon.png'
import Mob_App from '../../assets/images/icon3.png'
import { Element } from 'react-scroll'

function Solutions() {
    return (
        <Element name='Advantages'>
            <div className='' id=''>
                <div className='text-center bg-white pb-4 p-3' >
                    <h2 className='text-uppercase mb-0 '>The Athees Info advantages</h2>
                </div>
                <div className='container-fluid' style={{ backgroundImage: `url(${dot})`, backgroundSize: "1000px", backgroundPosition: "bottom", padding: "60px" }}>
                    <div className='row '>
                        <div className='col-12 col-lg-5 mx-auto p-1  bg-white rounded'>
                            <div className='container'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <img src={brick_logo} alt='brick_logo' style={{ width: "27%" }} />
                                        <h5 className='ml-2' style={{ color: "#563D7C", fontWeight: "700" }}>Construction Management</h5>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <p className='text-justify mb-0' style={{ fontSize: "14px" }}>
                                        Empower Your Team and Take Control with Our Cutting Edge Construction Management Application!
                                    </p>
                                    <ol className='mt-2 pl-3' style={{fontSize:"14px"}}>
                                        <li>Data-Driven Decision Making</li>
                                        <li>Enhanced Communication and Collaboration</li>
                                        <li>Early and Effective cost and Risk Management</li>
                                    </ol>
                                </div>
                                <div className='col-12'>
                                    <table className='table table-borderless '>
                                        <tbody>
                                            <tr className='text-primary'>
                                                <td style={{ fontSize: "13px" }}><img src={img1} alt='' width={18} />&nbsp;Structures based item of work</td>
                                                <td style={{ fontSize: "13px" }}><img src={img2} alt='' width={18} />&nbsp;Chainage mapping</td>
                                            </tr>
                                            <tr className='text-primary'>
                                                <td style={{ fontSize: "13px" }}><img src={img3} alt='' width={18} />&nbsp;Material management</td>
                                                <td style={{ fontSize: "13px" }}><img src={img1} alt='' width={18} />&nbsp;Monitoring</td>
                                            </tr>
                                            <tr className='text-primary'>
                                                <td style={{ fontSize: "13px" }}><img src={img4} alt='' width={18} />&nbsp;Human resource management</td>
                                                <td style={{ fontSize: "13px" }}><img src={img5} alt='' width={18} />&nbsp;Billing</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-5 mx-auto mt-2 mt-lg-0 p-1 pt-2 bg-white rounded'>
                            <div className='container'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <img src={sarasu_logo} alt='sarasu_logo' style={{ width: "29%" }} />
                                        <h5 className='ml-2' style={{ color: "#563D7C", fontWeight: "700" }}>Online Testing Practice</h5>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <p className='text-justify mb-0' style={{ fontSize: "14px" }}>We provide customized disciplined testing practice align with the school time schedule for
                                        Physics, Chemistry & Biology.
                                        SARASU NEET Online Testing Practice software has a wide-range of test practice, evaluation and training management
                                        platform that helps students to excel the accuracy, speed and tackle difficult indirect question at ease. </p>
                                </div>
                                <div className='col-12'>
                                    <table className='table table-borderless '>
                                        <tbody>
                                            <tr className='text-primary'>
                                                <td style={{ fontSize: "13px" }}><img src={img6} alt='' width={18} />&nbsp;Testing practice</td>
                                                <td style={{ fontSize: "13px" }}><img src={img7} alt='' width={18} />&nbsp;Partnering with schools</td>
                                            </tr>
                                            <tr className='text-primary'>
                                                <td style={{ fontSize: "13px" }}><img src={img8} alt='' width={18} />&nbsp;Capability analysis reports</td>
                                                <td style={{ fontSize: "13px" }}><img src={img9} alt='' width={18} />&nbsp;AI formulates test schedules</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-5 mx-auto mt-2 p-1 pt-2 bg-white rounded'>
                            <div className='container'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center mb-3'>
                                        <img src={Mob_App} alt='Mob_App' style={{ width: "13%" }} />
                                        <h5 className='ml-2' style={{ color: "#563D7C", fontWeight: "700" }}>Web Application</h5>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <p className='text-justify mb-0' style={{ fontSize: "14px" }}>
                                        We are Affordable Enterprise app
                                        Development Company
                                        Our team is proficient to ideate and launching web portals and custom built-websites. They have in-depth knowledge of various technology like Java, Spring, MERN stack, MEAN Stack, Fullstack, Python, Django
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-lg-5 mx-auto mt-2  p-1 pb-2 pt-2 bg-white rounded'>
                            <div className='container'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Web_App} alt='Web_App' style={{ width: "15%" }} />
                                        <h5 className='ml-2' style={{ color: "#563D7C", fontWeight: "700" }}>
                                            Mobile Application
                                        </h5>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <p className='text-justify mb-0' style={{ fontSize: "14px" }}>
                                        we provide a wide range of services for all kinds of businesses. You can leverage your brand idea with our mobile app development services. The tailor-made & customized solutions that we offer are built to target your unique business model.

                                        Our top-of-the-line professionals provide highly tailored solutions that leverage the robust app development framework. Our techstack includes hybrid off react native, flutter and Native app development ios Android.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default Solutions
