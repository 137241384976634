import React, { useState } from 'react';
import './Partner.css'
import banner from '../../../images/Icons_and_images/intro_image.png'
 import icon1 from '../../../images/Icons_and_images/manage multiple project.png'
 import icon2 from'../../../images/Icons_and_images/labour_attendance.png'
 import icon3 from'../../../images/Icons_and_images/site reporting.png'
 import icon4 from'../../../images/Icons_and_images/task and issues.png'
 import icon5 from'../../../images/Icons_and_images/manage budgeting.png'
 import icon6 from'../../../images/Icons_and_images/material request.png'

import { Link } from 'react-router-dom'
import "@fontsource/manrope"; 
import "@fontsource/syne";
import m from '../../../images/Icons_and_images/manage_multiple_project_hover.png'

const Projectprogress = ({}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div >
    <div class="projectmgntcont pt-5">
      <div class="row ">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
    {/* <center>  <p className='bluehead90'>   &lt;&lt;&lt; &nbsp;&nbsp; Manage Your Projects &nbsp;&nbsp; &gt;&gt;&gt;</p></center> */}
    <center>  <p className='partners1'>Manage Your Projects<br></br>
     </p></center>
        </div>
        <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12  ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div className="rounded division">
  <div className="small-division">
    <div className="icon-and-number">
    <img
                  src={isHovered ? require('../../../images/Icons_and_images/task and issues hover.png') : icon1}
                  width="40px"
                  height="40px"
                  alt="not found"
                  className="iconpad"
                />
      <font className="divitxt">01</font>
    </div>
  </div>
  <br></br>
  
   <div className="headingfont2">
    <h5>Promoters & 
      <br></br> LandLord</h5>
  </div>
 
  <div className="formfont">
Resource Allocation and Optimization
<br>
</br>
Cost Management
<br></br>
Real-time Project Tracking
</div>

</div>


</div>

<div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div className="rounded division">
  <div className="small-division">
    <div className="icon-and-number">
    <img
                  src={isHovered ? require('../../../images/Icons_and_images/manage_multiple_project_hover.png') : icon2}
                  width="40px"
                  height="40px"
                  alt="not found"
                  className="iconpad"
                />
      <font className="divitxt">02</font>
    </div>
  </div>
  <br></br>
  <div className="headingfont2">
    <h5>Contrators
      <br></br></h5>
  </div>
  <div className="formfont">
Effective Planning and Planning<br>
</br>
Enhance Transpering with Clients on Project Status<br>
</br>
Cost and Resource Management
<br></br>
{/* Real-time Project Status */}
<br>
</br>
{/* Efficient Vendor and SubContractor Management */}
<br></br>
{/* Data Driven Decision Making */}
  </div>
</div>
</div>
<div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12  ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div className="rounded division">
  <div className="small-division">
    <div className="icon-and-number">
    <img
                  src={isHovered ? require('../../../images/Icons_and_images/site reporting hover.png') : icon3}
                  width="40px"
                  height="40px"
                  alt="not found"
                  className="iconpad"
                />
      <font className="divitxt">03</font>
    </div>
  </div>
  <br></br>
  <div className="headingfont2">
    
    <h5> Site 
     <br></br>Engineer</h5>
     
   </div>
  <div className="formfont">
  Smooth Collaboration With SubContractors and Vendors.<br></br>
Real Time Project Update
{/* <br></br>ForeCating to Avoid Bottlenecks */}
{/* <br></br>Effective Communication Between Office and Site  */}
 
 </div>
</div>
</div>       

      </div>
    </div>
  </div>

  )
}
export default Projectprogress
