import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/ion_timer.png'
const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid">
      <div className="lightgrayback pb-5 pt-5">
        <center>
          <img src={icon} />
        </center>
        <font className="bigtextblack ">
          <center>
            Speed Trainer <font className="colortext1">NEET</font> Testing
            Practice
          </center>
        </font>

        <div className="spaceontop">
          <font className="normaltextblack">
            <div className="textpadspace">
              <center>
                <b>SARASU</b> helps students to increase their speed & answering
                accuracy in <b>NEET</b> competitive exam. Avoid last minute
                panic & careless mistakes with the help of disciplined testing
                practice. In this program students will be evaluated and will
                analyze their current answering speed & accuracy based on the
                outcome of the analysis. We provide <b>3 stages</b> of testing
                practice based on the subject/ chapter students choose
              </center>
            </div>
          </font>
        </div>
      </div>
    </div>
  )
}

export default Gallery
