import React from 'react'
import './Imagecard.css'
// import '..TextonImageBrick/TextonImage.css'
import banner from '../../../images/Icons_and_images/intro_image.png'
import { Link } from 'react-router-dom'
import card1 from '../../../images/Icons_and_images/project-1.jpg'
import card2 from '../../../images/Icons_and_images/project-2.jpg'
import card3 from '../../../images/Icons_and_images/project-3.jpg'
import card4 from '../../../images/Icons_and_images/project-4.jpg'
function Imagecard() {
  return (
    <div class='container-fluid bgm'>
    
      <div class ="row ">

        <div class ="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gfr">
    <center>Let's Accomplish 
     <br></br>more, together
</center>
</div>
</div>
<div class ="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="containerimage">
        <div class="row ">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"></div>
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
    <div class="imagecontainer1">
  <img className='carddi' src={card1} alt="Avatar" class="image" />
  <div class="overlay2"></div>
</div>
</div>
<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
    <div class="imagecontainer2">
  <img src={card2} alt="Avatar" class="image" />
  <div class="overlay2"></div>
</div>
</div>
<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
    <div class="imagecontainer3">
  <img src={card3} alt="Avatar" class="image" />
  <div class="overlay2"></div>
</div>
</div>

<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
<div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2">
</div>
    <div class="imagecontainer4">
  <img src={card4} alt="Avatar" class="image" />
  <div class="overlay2"></div>
</div>
</div>
</div>
             
</div>
        </div>
        </div>
        
  )
}
export default Imagecard
