import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/fa-solid_school.png'
const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid">
      <center>
        <img src={icon} />
      </center>
      <font className="bigtextblack ">
        <center>
          <font className="colortext1">School</font> Admin Login
        </center>
      </font>

      <div className="spaceontop">
        <div className="textpadspace">
          <font className="graytext ">
            <center>
              We partner with schools and help them provide quality, disciplined
              testing practice and train their students in the aspect of speed
              and accuracy in facing competitive exam we align with the school
              time schedule and curriculum, enable them to evaluate and train
              their students with the help of our software. It helps find each
              students strength, weakness through capability analysis reports by
              advanced Al system, where the teachers need not do capability
              analysis manually.
            </center>
          </font>
        </div>
      </div>
    </div>
  )
}

export default Gallery
