import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import HaveQuestions from '../Component/HaveQuestions/HaveQuestions';
import Contactuspage from '../Component/Contactus/contactus';
import Letstalkpage from '../Component/Letstalk/letstalk';

import { useState } from "react";
export default function ContactUs  ()  {
const[firstname,setFirstname]=useState('')
const[lastname,setLastname]=useState('')
const[email,setEmail]=useState('')
const[phoneNo,setPhoneNo]=useState('')
const[message,setMessage]=useState('')
const form = useRef();


return (

<div class=" ">
<Contactuspage/>
<Letstalkpage/>

 {/* <HaveQuestions /> */}

</div>


);
};