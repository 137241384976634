import React, { useState } from "react";
import "./aiinfo.css";

import icon1 from "../../assets/images/Constructionengineer.png";
import icon2 from "../../assets/images/administratorsystem.png";
import icon3 from "../../assets/images/icon3.png";
import icon4 from "../../assets/images/mobappicon.png";
import { Link } from "react-router-dom";
import "@fontsource/manrope";
import "@fontsource/syne";
import m from "../../assets/images/Icons_and_images/manage_multiple_project_hover.png";

const AIinfo = ({}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <div class="projectmgntcont container  ">
        <div class="row mx-auto " >
          <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 ">
            <center>
              
              <p
                className="headingai lead display-4"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                THE ATHEES INFO ADVANTAGE
              </p>
            </center>
          </div>
          <div
            className='col-lg-6 col-xl-6 col-md-4 col-sm-2 col-12 ' 
          >
            <div
              className=" shadow-lg  division1 " style={{marginRight:"70px"}}
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="icon-and-number">
                <img
                  src={icon1}
                  width="70px"
                  height="40px"
                  alt="not found"
                  className="iconpadab"
                />
              </div>

              <br></br>
              <div className="headingfontc">
                <p>CONSTRUCTION MANAGEMENT</p>
              </div>
              <div className="formfontc">
                Process Transformation, Business Model Transformation,Bringingin
                Nextgen Technology{" "}
              </div>
            </div>
          </div>
          
          <div
            className={`col-lg-4 col-xl-6 col-md-4 col-sm-4 col-12 ${
              isHovered ? "hovered" : ""
            }`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div
              className="shadow-lg division1"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="icon-and-number">
                <img
                  src={icon2}
                  width="40px"
                  height="40px"
                  alt="not found"
                  className="iconpadab"
                />
              </div>

              <br></br>
              <div className="">
                <h5 className="headingfontc">SCHOOL ADMIN </h5>
              </div>
              <div className="formfontc">
                End to end Automation Testing, Load Testing, Performance
                Testing, Backend system Testing.
              </div>
            </div>
          </div>

          <div
            className={`col-lg-4 col-xl-6 col-md-4 col-sm-6 col-12 ${
              isHovered ? "hovered" : ""
            }`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div
              className=" shadow-lg  division1"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="icon-and-number">
                <img
                  src={icon3}
                  width="50px"
                  height="50px"
                  alt="not found"
                  className="iconpadab"
                />
              </div>

              <br></br>
              
            </div>
          </div>
          
          <div
            className={`col-lg-4 col-xl-6 col-md-4 col-sm-6 col-12 ${
              isHovered ? "hovered" : ""
            }`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div
              className=" shadow-lg division1"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="icon-and-number">
                <img
                  src={icon4}
                  width="50px"
                  height="50px"
                  alt="not found"
                  className="iconpadab"
                />
              </div>

              <br></br>
              <div className="">
                <h5 className="headingfontc">MOBILE APPLICATION </h5>
              </div>
              <div className="formfontc">
                End to end Automation Testing, Load Testing, Performance
                Testing, Backend system Testing.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AIinfo;
