import React from 'react'
import './Content.css'
import icon1 from '../../assetsS/images/boxicon3.png'
import icon2 from '../../assetsS/images/boxicon2.png'
import icon3 from '../../assetsS/images/boxicon1.png'

export default class TextonImage extends React.Component {
  state = {}

  updateDetails() {
    this.props.handleStateChange(this.state)
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps)
  }

  componentDidMount = async () => {
    this.setState(this.props)
  }

  render() {
    const isBgImg = this.state.isBgImg

    return (
      <div className="container pt-4 pb-5">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-4 col-12 mb-5 ">
            <div className="rounded newdivisionz">
              <center>
                <img src={icon1} width={70} className="icontopbotpad" />
              </center>
              <div className="headtextsize">
                <center>
                  1.&nbsp;Exclusive NEET Testing Practice Progress
                  Tracker&nbsp;:
                </center>
              </div>
              <div className="paratextsize ">
                <center>
                  Monitor the students study progress. After each lessons
                  teacher can assign the test practice to students online.
                  Monitor the performance of each and every student and create
                  individualized test plans based on students strength and
                  weakness.
                </center>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-xl-4 col-12 mb-5">
            <div className="rounded newdivisionz">
              <center>
                <img src={icon2} width={70} className="icontopbotpad" />
              </center>
              <div className="headtextsize">
                <center>2.&nbsp; Automated NEET Test Assessment&nbsp;:</center>
              </div>
              <div className="paratextsize ">
                <center>
                  With the help of the AI-Powered Assessments Generator,
                  generate thousands of tests in just clicks. Based on students
                  test results AI-formulates individualized exclusive test
                  practice.
                </center>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-xl-4 col-12 mb-5">
            <div className="rounded newdivisionz">
              <center>
                <img src={icon3} width={70} className="icontopbotpad" />
              </center>
              <div className="headtextsize">
                <center>
                  3.&nbsp; Cloud-Based NEET Online Study Tips & Solutions:
                </center>
              </div>
              <div className="paratextsize">
                <center>
                  Enable flexible learning for your students with immersive
                  digital content available anytime, anywhere.
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
