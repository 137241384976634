import React from 'react';
import './ProjectProgress.css';
import banner from '../../assets/images/Icons_and_images/intro_image.png';
import { Link } from 'react-router-dom';
import "@fontsource/manrope"; 
import "@fontsource/syne";
function Projectprogress() {
  return (
    <div className="container imagetexttop mt-5 mb-5">
      <div className="row ">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div id="box">
            <img src={banner} alt="not found" className="responsive-image51" />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="padprogress5 ">
            <font className="bluehead5">
            &lt;&lt;&lt; &nbsp;&nbsp;Welcome Everyone &nbsp;&nbsp; &gt;&gt;&gt;
            </font>
             <br></br> 
             
            <font className="blackhead5">Project Progress </font>
             <br></br> 
            <font className="blackhead5"> Visibility & Monitoring</font>
          <br></br> 
            <font className="bluesmall">The professional approach to development</font>
             <br></br> 

            <font className="graytxt5">
            Construction encompasses the art and science of creating objects, systems, and organizations, 
            drawing from Latin constructio and Old French roots. 
            </font>
            <div>
            <br></br>
              {/* <Link to="/RequestDemo">
                <button type="button" className="btn btn-primary ">
                  <b>Discover More</b>
                </button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Projectprogress;
