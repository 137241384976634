import React, { useState } from 'react';
import './ProjectMgnt.css'
import banner from '../../assets/images/Icons_and_images/intro_image.png'
import icon1 from '../../assets/services/digital transform.png'
import icon2 from '../../assets/services/automation.png'
import icon3 from '../../assets/services/data analytics.png'
import icon4 from '../../assets/services/application develepment.png'
import icon5 from '../../assets/services/mobile app.png'
import icon6 from '../../assets/services/devops.png'

import { Link } from 'react-router-dom'
import "@fontsource/manrope";
import "@fontsource/syne";
import m from '../../assets/images/Icons_and_images/manage_multiple_project_hover.png'
import { Element } from 'react-scroll';

const Projectprogress = ({ }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div >
      <Element name='Services'>
        <div class="projectmgntcont container-fluid bg-white ">
          <div class="row ">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
              {/* <center>  <p className='bluehead90'>   &lt;&lt;&lt; &nbsp;&nbsp; Services &nbsp;&nbsp; &gt;&gt;&gt;</p></center> */}
              <center>  <p className='blackhead1 mt-5'>Our Services</p></center>
            </div>
            <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12  ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div className="rounded division">
                <div className="small-division">
                  <div className="icon-and-number">
                    <img
                      src={isHovered ? require('../../assets/services/digital transform white.png') : icon1}
                      width="40px"
                      height="40px"
                      alt="not found"
                      className="iconpad"
                    />
                    <font className="divitxt">01</font>
                  </div>
                </div>
                <br></br>
                <div className="headingfont2">
                  <h5>Digital <br></br>Transformation</h5>
                </div>
                <div className="formfont">
                  Process Transformation, Business Model Transformation,Bringingin Nextgen Technology  </div>
              </div>
            </div>

            <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div className="rounded division">
                <div className="small-division">
                  <div className="icon-and-number">
                    <img
                      src={isHovered ? require('../../assets/services/automation white.png') : icon2}
                      width="40px"
                      height="40px"
                      alt="not found"
                      className="iconpad"
                    />
                    <font className="divitxt">02</font>
                  </div>
                </div>
                <br></br>
                <div className="headingfont2">
                  <h5> Quality Engineering
                    <br></br>and Automation</h5>
                </div>
                <div className="formfont">
                  End to end Automation Testing, Load Testing, Performance Testing, Backend system Testing.
                </div>

              </div>
            </div>
            <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12  ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div className="rounded division">
                <div className="small-division">
                  <div className="icon-and-number">
                    <img
                      src={isHovered ? require('../../assets/services/data analytics white.png') : icon3}
                      width="40px"
                      height="40px"
                      alt="not found"
                      className="iconpad"
                    />
                    <font className="divitxt">03</font>
                  </div>
                </div>
                <br></br>
                <div className="headingfont2">
                  <h5> Data Analytics
                    <br></br> and AI </h5>
                </div>
                <div className="formfont">
                  Data Visualization, Predictive Analysis, Personolization, Chatbots. </div>
              </div>
            </div>
            <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div className="rounded division">
                <div className="small-division">
                  <div className="icon-and-number">
                    <img
                      src={isHovered ? require('../../assets/services/application develepment white.png') : icon4}
                      width="40px"
                      height="40px"
                      alt="not found"
                      className="iconpad"
                    />
                    <font className="divitxt">04</font>
                  </div>
                </div>
                <br></br>
                <div className="headingfont2">
                  <h5>Embedded Application
                    <br></br> and Development</h5>
                </div>
                <div className="formfont">
                  Networked embedded systems, Mobile embedded systems, Stand alone embedded system  </div>
              </div>
            </div>

            <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6 col-12 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div className="rounded division">
                <div className="small-division">
                  <div className="icon-and-number">
                    <img
                      src={isHovered ? require('../../assets/services/mobile app white.png') : icon5}
                      width="40px"
                      height="40px"
                      alt="not found"
                      className="iconpad"
                    />
                    <font className="divitxt">05</font>
                  </div>
                </div>
                <br></br>
                <div className="headingfont2">

                  <h5> Mobile App
                    <br></br>Development</h5>

                </div>

                <div className="formfont">
                  Andriod app, IOS app, React Native, Cross platform app-Flutter.
                </div>
                {/*  */}
              </div>
            </div>
            <div className={`col-lg-4 col-xl-4 col-md-4 col-sm-6  col-12 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              <div className="rounded division">
                <div className="small-division">
                  <div className="icon-and-number">
                    <img
                      src={isHovered ? require('../../assets/services/devops white.png') : icon6}
                      width="40px"
                      height="40px"
                      alt="not found"
                      className="iconpad"
                    />
                    <font className="divitxt">06</font>
                  </div>
                </div>
                <br></br>
                <div className="headingfont2">
                  <h5> Devops</h5>
                </div>
                <div className="formfont">
                  PaaS-Platform as a Service, IaaS-Infrastructure as a Service, System Integration  </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </div>

  )
}
export default Projectprogress
