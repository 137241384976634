import React from "react";
import "./cm.css";
import w1 from "../../assets/images/welcom bg 4.png";
import w2 from "../../assets/images/commercial.png";
function Cm(){
    return(<>
    <div className="container-fluid ctimga" >
        <div className="container">
      <p className=" up1"data-aos="fade-right"
     data-aos-duration="3000">CONSTRUCTION  MANAGEMENT
     <h4 className="up2" >Human Resource Management</h4></p>
    </div>
   </div>
     
     
     
     <div className="ctimgb">
     <div class="container">
    <div class="row ">
       <div class="col-lg-6  col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 ">
        <p className='ccm'>About Us</p>
        <p className='ccmtag'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 pt-5">
        
       <div id="box"><center><img className='pt-5' src={w2} alt="not found" width='100%' height='100%' ></img></center>  </div>
       </div>
        </div>
        </div> 
       
    </div>
    </>)
}
export default Cm