import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/stage.png'
import Samplevideo from '../../assetsS/Videos/SpeedTrainer.mp4'
import speedtrainer from '../../assetsS/firstcomponent/speedtrainerthum.jpg'

const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid">
      <div class="row lightgrayback  textpadspace1">
        <div
          class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 "
          id="cardimg"
        >
          <img src={icon} />
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="biochamppad1">
            <video controls width="100%" poster={speedtrainer}>
              <source src={Samplevideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
