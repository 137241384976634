import React, { useEffect, useState } from "react";
import logo1 from "../../assets/images/Icons_and_images/athees_header_logo.png";
import location from "../../assets/images/Icons_and_images/location.png";
import mail from "../../assets/images/Icons_and_images/mail.png";
import facebook from "../../assets/images/Icons_and_images/facebook.png";
import whatsapp from "../../assets/images/Icons_and_images/whatsapp.png";
import phone from "../../assets/images/Icons_and_images/Phone.png";
import "./Header.css";
import "@fontsource/manrope";
import "@fontsource/syne";
// import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


export default function Header() {

  useEffect(() => {

    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      // console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      // console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  // // Defining functions to perform different types of scrolling.
  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };


  return (
    <div className="headersizediv ">
      <div className="headfirst">
        <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12"></div>
      </div>


      <Navbar expand="lg" className="bg-body-tertiary position-sticky bg-white p-0 border-bottom ">
        <Container className="">
          <Navbar.Brand href="#home">
            <img
              src={logo1}
              className="img-fluid"
              alt="not found"
              width={100}
              height={75}
              align="left" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className="mr-5" href="/" >
                {/* <Link activeClass="active" spy={true} smooth={true} offset={-100}
                duration={500} to="home" className="nav-link p-0"> */}
                Home
                {/* </Link> */}
              </Nav.Link>

              {/* <Nav.Link className="mr-5" href="/">
              <Link activeClass="active" spy={true} smooth={true} offset={-100}
                duration={500} to="Advantages" className="nav-link p-0">About Us</Link>
                </Nav.Link> */}
              <NavDropdown className="mr-5" title="Our Products" id="basic-nav-dropdown" style={{ zIndex: "" }}>
                <NavDropdown.Item href="/Brick">Brick</NavDropdown.Item>
                <NavDropdown.Item href="/SchoolPartnership">
                  Sarasu
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="mr-5" href="/Contactus">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
    </div>
  );
}
