import React from 'react'
import './Content.css'
// import icon1 from '../../assetsS/images/icon1.png'
import icon2 from '../../assetsS/images/boxicon2.png'
import icon3 from '../../assetsS/images/boxicon1.png'
// import Photo4 from '../../assetsS/images/back4.png'
export default class TextonImage extends React.Component {
  state = {}

  updateDetails() {
    this.props.handleStateChange(this.state)
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps)
  }

  componentDidMount = async () => {
    this.setState(this.props)
  }

  render() {
    const isBgImg = this.state.isBgImg

    return (
      <div
        className="homebannerimg1"
        // style={{ backgroundImage: `url(${Photo4})` }}
      >
        <div class="text-overlay1comp25">
          <div className="leftrightimagemargin">
            <font className="bigtextblack">
              <center>
                <font className="colortext1">Features</font> of Competitive Exam
                Testing Practice & Evaluation Management Software
              </center>
            </font>

            <div className="leftrightimagemargin">
              <div className="spaceontop">
                <font className="normaltextblack">
                  <center>
                    <b>SARASU NEET</b> Testing Practice cloud-based test
                    management platform app that manages your testing and
                    evaluation process of your students who are Doctor
                    aspirants.
                  </center>
                </font>
              </div>
            </div>
          </div>
          <div className="container pt-5">
            <div className="bluebox">
              <div>
                {' '}
                <font className="blueboxhead">
                  {' '}
                  Chapter wise testing practice :
                </font>
              </div>
              <div class="pt-2">
                <font className="blueboxsmalltext">
                  Once the chapter gets completed the respective teacher can a
                  lot test to the students. Students will get the list of
                  questions (MCQ’s) from that particular chapter. All these
                  MCQ’s are carefully designed by our specialist board. Our
                  specialist board consist of expert educators who will be
                  responsible for creating Questions with their vast experience
                  and knowledge.
                </font>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="container pt-4">
            <div className="bluebox">
              <div>
                {' '}
                <font className="blueboxhead">Capability Analysis report:</font>
              </div>

              <div class="pt-2">
                <font className="blueboxsmalltext ">
                  After each test students will get the test results with
                  details capability analysis report
                  <br></br>
                  I. Avg Time taken per each questions subject wise
                  <br></br>
                  II. Avg Time taken per indirect questions subject wise
                  <br></br>
                  III. Avg Time taken per direct questions subject wise
                  <br></br>
                  IV. Accuracy rate per subject
                  <br></br>
                  V. Stamina maintained over the duration of the exam
                  <br></br>
                  VI. -Negative answer
                </font>
              </div>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="container pt-4">
            <div className="bluebox">
              <div>
                {' '}
                <font className="blueboxhead">
                  AI formulates individual students based on the specialized
                  testing practice:
                </font>
              </div>
              <div class="pt-2">
                <font className="blueboxsmalltext ">
                  Based on the above criteria AI based system suggest following
                  testing practice, Speed trainer, Biology champion league, Test
                  Marathon
                </font>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="container pt-4">
            <div className="bluebox">
              <div>
                {' '}
                <font className="blueboxhead"> Answers & Explanations:</font>
              </div>
              <div class="pt-2">
                <font className="blueboxsmalltext ">
                  Based Once after the exam system provides the answers &
                  detailed explanation also the concept behind that particular
                  question helps students face any kind of twisted questions
                </font>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    )
  }
}
