import React, { useState } from 'react';
import './Technology.css'
import selenium from '../../assets/Technologies/logo/selenium.png'
import postman from '../../assets/Technologies/logo/postman-logo-big.png'
import jmeter from '../../assets/Technologies/logo/Apache_JMeter.png'
import maven from '../../assets/Technologies/logo/Apache_Maven_logo.svg.png'
import git from '../../assets/Technologies/logo/git.png'
import android from '../../assets/Technologies/logo/android-logo.png'
import aws from '../../assets/Technologies/logo/Amazon_Web_Services.png'
import csshtml from '../../assets/Technologies/logo/CSS-Logo.png'
import python from '../../assets/Technologies/logo/Python-Logo.png'
import postgresql from '../../assets/Technologies/logo/PostgreSQL-Logo.png'
import oracle from '../../assets/Technologies/logo/Oracle-Logo.png'
import sql from '../../assets/Technologies/logo/Mysql_logo.png'
import mangodb from '../../assets/Technologies/logo/Mongodb.png'
import devops from '../../assets/Technologies/logo/logo-devops.png'
import reactjs from '../../assets/Technologies/logo/react.js-img.png'
import springboot from '../../assets/Technologies/logo/spring boot.png'
import ios from '../../assets/Technologies/logo/iOS-Symbol.png'

import java from '../../assets/Technologies/java.png'
import { Link } from 'react-router-dom'
import "@fontsource/manrope";
import "@fontsource/syne";
import { Element } from 'react-scroll';

const Projectprogress = ({ }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <Element name='Technologies'>
    <div class="container-fluid bg-white">
      <div className='row mx-auto  '>
        <div className='col-12 mb-3  texstyle mt-5 pt-4'>
          <p className='ml-5'><b>Technology</b> we play with ...</p>
        </div>

        <div className='col-12 mt-4 '>
          <div className='container-fluid '>
            <div className='row mx-auto'>
              <div className='col-12 '>
                <div className='container-fluid mb-3 '>
                  <div className='row'>
                    <div className='col-4 col-md-3 col-lg-2 text-center  p-3 video-c' >
                      <img src={springboot} height={80} width={80} alt={springboot}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={reactjs} height={70} width={80} alt={reactjs}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={ios} height={80} width={80} alt={ios}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={mangodb} height={100} width={100} alt={mangodb}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={sql} height={80} width={80} alt={sql}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c ' >
                      <img src={devops} height={80} width={80} alt={devops}></img>
                    </div>
                  
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={android} height={80} width={100} alt={android}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={csshtml} height={80} width={80} alt={csshtml}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={postgresql} height={80} width={140} alt={postgresql}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={oracle} height={80} width={80} alt={oracle}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={java} height={50} width={80} alt={java}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={aws} height={80} width={70} alt={aws}></img>
                    </div>
                  
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={selenium} height={100} width={100} alt={selenium}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={postman} height={100} width={140} alt={postman}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={jmeter} height={100} width={100} alt={jmeter}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={maven} height={100} width={80} alt={maven}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={git} height={80} width={80} alt={git}></img>
                    </div>
                    <div className='col-4 col-md-3 col-lg-2 text-center p-3 video-c' >
                      <img src={python} height={80} width={100} alt={python}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Element>
  )
}
export default Projectprogress
