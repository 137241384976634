import React from 'react';
import { useSpring, useTrail, animated } from 'react-spring';
import banner2 from '../../../images/Icons_and_images/Banner-bg.jpg';
import banner from '../../../images/Icons_and_images/banner_image.png';
import card1 from '../../../images/Icons_and_images/image-1.jpg';
import card2 from '../../../images/Icons_and_images/image-2.jpg';
import card3 from '../../../images/Icons_and_images/image-3.jpg';
import iconimgst from '../../../images/Icons_and_images/business_owners.png';
import iconimgst1 from '../../../images/Icons_and_images/project planning.png';
import iconimgst2 from '../../../images/Icons_and_images/site engineers.png';
import LABOUR from '../../../images/Icons_and_images/LABOUR.jpg';
// import LABOUR1 from '../../assets/images/Road-Works1.jpg';
import Banner from '../../../images/changed_banner.png';
import Banner1 from '../../../images/changed_banner.png';
import Banner2 from '../../../images/5.png';
import Banner3 from '../../../images/newbannerblur.png';
import "@fontsource/manrope"; 
import "@fontsource/syne";
import { FaCheck } from 'react-icons/fa';


import { Link } from 'react-router-dom';
import './TextonImage.css';

function TextonImage() {
  const textOverImgProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  const phoneImageProps = useSpring({
    opacity: 1,
    transform: 'translateY(0%)',
    from: {
      opacity: 0,
      transform: 'translateY(100%)',
    },
    config: {
      duration: 1500, // Adjust the duration as needed
    },
  });
  const trackTextPropsf = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 4500,
  });
  const cardProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay:5500,

  });
  const trail = useTrail(3, {
    opacity: 1,
    from: { opacity: 0 },
    delay: [5500, 9000, 6500], // Adjust the delays for each box
  });
  const welcomeTextProps = useSpring({
    marginLeft: 0,
    from: { marginLeft: -1000 },
    delay: 1500,
  });

  const trackTextProps = useSpring({
    marginLeft: 0,
    from: { marginLeft: -1000 },
    delay: 2000,

    config: {
      duration: 2000, // Adjust the duration as needed
    },
  });

  // const trackTextfProps = useSpring({
  //   from: { opacity: 1 },
  //   marginLeft: 0,
  //   delay: 3000,

   
  // });
  const trackTextfProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 3000,

    config: {
      duration: 3000, // Adjust the duration as needed
    },
  });

  const trackTextsfProps = useSpring({
    marginLeft: 0,
    from: { marginLeft: -1500 },
    delay: 3500,

    config: {
      duration: 3500, // Adjust the duration as needed
    },
  });

  const trackTexttfProps = useSpring({
    marginLeft: 0,
    from: { marginLeft: -1500 },
    delay: 4000,

    config: {
      duration: 4000, // Adjust the duration as needed
    },
  });
  const trackTextffProps = useSpring({
    marginLeft: 0,
    from: { marginLeft: -1500 },
    delay: 4500,

    config: {
      duration: 4500, // Adjust the duration as needed
    },
  });


  const requestDemoProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 5500,

    config: {
      duration: 5000, // Adjust the duration as needed
    },
  });

  return (
    <div className="">
      <animated.div
        className="textOverImgcontactus" id=""
        style={{
          backgroundImage: `url(${Banner3})`,
          
        }}
      >
        <div className="row uu">
          <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
            <div className="padall pt-3">
              {/* <animated.p style={welcomeTextProps} className="bluehead44">
                <u>Welcome to Brick</u>
              </animated.p> */}
              <animated.p style={trackTextProps} className="blackhead67 ">
              {/* Blueprints to Brilliance: */}
                Construction Management Made Easy
              </animated.p>
              <animated.p style={trackTextfProps} className='blackhead678'>Empower Your Team and Take Control with Our Cutting Edge Construction Management Application!  </animated.p>

              </div>
              <div class='row'>
              <div className="col-xl-1 col-lg-1 col-md-7 col-sm-12 col-12 "></div>

              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 pl-4">


              <animated.p style={trackTextsfProps} className='newtext'><FaCheck /> Data-Driven Decision Making</animated.p>
              <animated.p style={trackTexttfProps} className='newtext'><FaCheck /> Enhanced Communication and Collaboration</animated.p>
              <animated.p style={trackTextffProps} className='newtext'><FaCheck /> Early and Effective cost and Risk Management</animated.p>
              {/* <Link to="/RequestDemo">
                <animated.button
                  style={requestDemoProps}
                  type="button"
                  className="btncoloro"
                >
                  <b>Request Demo</b>
                </animated.button>
              </Link> */}
</div> 
{/* <div className="col-xl-5 col-lg-5 col-md-7 col-sm-12 col-12 pl-4"> */}

             
            {/* </div> */}
            </div>

            </div>

          {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 pt-5">
            <animated.img
              className="phoneimage"
              src={banner}
              alt="not found"
              style={phoneImageProps}
            />
          </div> */}
          {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <animated.p className="bluediv1" style={cardProps1}>
              How it Helps?
            </animated.p>
            <div className="row">
              {trail.map((props, index) => (
                <animated.div
                  key={index}
                  className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pl-5 pr-5"
                  style={cardProps}
                >
                  <div className="iconDiv1">
                    <div
                      style={{ backgroundImage: `url(${index === 0 ? card1 : index === 1 ? card2 : card3})` }}
                      className="textcardimage"
                    >
                      <div className="text-block">
                        <center>
                          <font className="boxinsidetextheading0">
                            {index === 0 ? 'Promoters &' : index === 1 ? 'Project Plan' : 'Site Engineer'}
                          </font>
                          <br></br>
                          <font className="boxinsidetextheading0">
                            {index === 0 ? 'Land' : index === 1 ? 'Manager' : 'Supervisor'}
                          </font>
                        </center>
                        <center>
                          <p>
                            A brick is a type construct
                            <br></br>material used to build walls.
                          </p>
                        </center>
                        <div className="iconmargin">
                          <center>
                            <animated.img
                              className="iconim"

                              src={index === 0 ? iconimgst : index === 1 ? iconimgst1 : iconimgst2}
                              width={60}
                              alt="not found"
                            />
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-0 col-0"></div>

                </animated.div>
              ))}

            </div>

          </div> */}

        </div>
      </animated.div>
    </div>
  );
}

export default TextonImage;
