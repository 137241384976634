import React from 'react'
import banner2 from '../assets/images/Icons_and_images/Banner-bg.jpg'
import logo from '../assets/images/Icons_and_images/logo.jpg'
import Login from '../Component/LoginForm/LoginForm'
import { Link } from 'react-router-dom'
import '../util/LoginandRegistraion'

function LoginandRegistration() {
    return (

        <div
            class="freebg"
            style={{ backgroundImage: `url(${banner2})` }}
        >
            <div>

                <div className='row'>
                    <div class="col-lg-2 col-xl-2">  </div>
                    <div class="col-lg-8 col-xl-8 ">
                        <div className='formback'>
                            <form>
                                <div class="row">

                                    <div class="col-lg-6 col-xl-6 ">
                                        <div className='foursidepad '>
                                   <img src={logo} />
                                   <div class="pt-3 pb-3">
                                 <h3><b>Avoid delays & Save</b></h3>
                              <h3><b>upto 7% on project costs</b></h3>
                              <ul class="pt-3 pb-2 " id="courselist">
<li class="pricepad">
  Topic wise exam (Only +2 Syllabus)
</li>
<li class="pricepad">
  Cumulative exam once every 2 weeks 
 
  (Including +1 Topics)
</li>
<li class="pricepad">
Detailed report with capability analysis
</li>
</ul>
<center>
                        <button
                          type="submit"
                          className="login-btn1"
                          name="submit"
                        >
                          <b>Next &nbsp;&nbsp; <i class='fas fa-arrow-right' ></i></b>
                        </button>
                      </center>{' '}

                              </div>
                                    </div>
                                    </div>

                                    <div class="col-lg-6 col-xl-6">
<Login />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-2 col-xl-2">  </div>
                </div>

            </div>
        </div>

    )
}
export default LoginandRegistration
