import React from 'react';
import banner from '../../../images/home_banner_without_text.png';
import './WeareHere.css';
import backgnd from '../../../images/Icons_and_images/Let-join-togetehr-image.jpg';
import { Link } from 'react-router-dom';
import Imagecard from '../Imagecard/Imagecard'

const WeareHere=({pagename}) =>{
  const backgroundImageStyle = {
    backgroundImage: `url(${backgnd})`,
    backgroundSize: '100% 200%', // Adjust the second value (height) as needed
    backgroundPosition: 'center bottom', 
      
  };
  console.log(pagename)
  return (
    <div className="container-fluid bgf">
        <div>
{pagename==='Home' && <Imagecard />}
        </div>
      <div className="textOverImgcontactus1" style={backgroundImageStyle}>
        <div className="row fullwidth">
          <div className="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-md-3 col-3"></div>
          <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-md-6 col-6">
            
            <div className="padwearehere test">
                <font className="whitetxt11">
                  &lt;&lt;&lt; &nbsp;&nbsp;We Are Here &nbsp;&nbsp; &gt;&gt;&gt;
                </font>

              <p className="whitehead">Streamline your workflow with  Our Construction Management &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Solution.</p>
                {/* <Link to='/RequestDemo'>
                  <button type="button" className="btncolor2">
                    <b>Request Demo </b>
                  </button>
                </Link> */}
            </div>
          </div>
          <div className="col-lg-3 col-xl-3"></div>
        </div>
      </div>
    </div>
  );
}

export default WeareHere;
