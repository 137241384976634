import { Outlet, Link } from 'react-router-dom'
import emailjs from 'emailjs-com';
import React from 'react'
import { useState, useEffect } from 'react'
import './RequestDemo.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText';
import mappic from '../../assets/images/getintouch.png'
// import axios from 'axios'
// import {baseurl} from '../BaseUrl/Baseurl'
										  
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [fname, setFname] = useState('')
  const [firstnameValid, setFirstnameValid] = useState(false)
  const [firstnameerror, setFirstnameerror] = useState('')
  const [name2, setName2] = useState('')
  const [lastnameValid, setLastnameValid] = useState(false)
  const [lastnameerror, setLastnameerror] = useState('')
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [emailerror, setEmailerror] = useState('')
  const [phno, setPhno] = useState('')
  const [phnoValid, setPhnoValid] = useState(false)
  const [phnoerror, setPhnoerror] = useState('')
  const [desig, setDesig] = useState('')
  const [designationValid, setDesignationValid] = useState(false)
  const [designationerror, setDesignationerror] = useState('')
  const [message, setMessage] = useState('')
  const [companyname,setCompanyname] = useState('')
  const [companynameerror,setCompanynameerror] = useState('')
  const [companynameValid,setCompanynameValid] = useState(false)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    }, [])

  function handlefirstname(e) {
 
   console.log(e.target.value)
    setFname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleChangeLName(e) {
    setName2(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleChangePhno(e) {
    setPhno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleChangeEmail(e) {
    setEmail(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handlemessage(e) {
    console.log(message)
    setMessage(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleChangeDesig(e) {
    setDesig(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleChangeCompanyname(e) {
    setCompanyname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'fname') {
      console.log(value)
      if (value.length != 0) {
        setFirstnameerror('')
        setFirstnameValid(true)
      } else {
        setFirstnameerror('Please enter your First name')
        setFirstnameValid(false)
      }
    }
    // if (fieldName === 'name2') {
    //   if (value.length != 0) {
    //     setLastnameerror('')
    //     setLastnameValid(true)
    //   } else {
    //     setLastnameerror('Please enter your name')
    //     setLastnameValid(false)
    //   }
    // }
    if (fieldName === 'phno') {
      if (value.length == 10) {
        setPhnoerror('')
        setPhnoValid(true)
      } else {
        setPhnoerror('Please enter valid phone number')
        setPhnoValid(false)
      }
    }
    if (fieldName === 'email') {
      const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (value.length != 0) {
        console.log(value.match(regex))
        if (value.match(regex)) {
          console.log('true')
          setEmailValid(true)
          setEmailerror('')
        } else {
          console.log('false1')
          setEmailValid(false)
          setEmailerror('Please enter valid email')
        }
      } else {
        console.log('false2')
        setEmailValid(false)
        setEmailerror('Please enter valid email')
      }
    }
    if (fieldName === 'desig') {
      if (value.length != 0) {
        setDesignationerror('')
        setDesignationValid(true)
      } else {
        setDesignationerror('Please enter your Designation')
        setDesignationValid(false)
      }
    }
    if (fieldName === 'companyname') {
      if (value.length != 0) {
        setCompanynameerror('')
        setCompanynameValid(true)
      } else {
        setCompanynameerror('Please enter your Company name')
        setCompanynameValid(false)
      }
    }
  }

  function onSubmitEvent(event) {
    event.preventDefault()
    
  console.log(fname)
  console.log(name2)
  console.log(desig)
  console.log(message)
  console.log(email)
  console.log(phno)

  
  if (companynameValid && firstnameValid && emailValid && phnoValid && designationValid) {
				  
						

    // let userData = new FormData()
    // userData.append('designation', desig)
    // userData.append('contact_person', fname)
    // userData.append('company_name', companyname)
    // userData.append('contact_number',phno)
    // userData.append('contact_email',email)
    // userData.append('status_comment',message )
    //   axios({
    //     method: 'POST',
  
    //     url: baseurl + '/api/request/trial/new',
  
    //     headers: {
    //       'Content-Type': 'multipart/form-data',     
        
    //     },
  
    //     data: userData,
    //   })
        
    //   .then((response) => {
							   
    //   })
    //   .catch((error) => {
    //      console.log(error)
    //    // setErrormsg(error.response.data.message)
    //   })
    event.preventDefault();

    // Send email using EmailJS
    emailjs.send('service_v2yzb9o', 'template_j4nsr6r', {
      from_name:fname,
      designation: desig,
      reply_to: email,
      companyname: companyname,
      phone_number: phno,
      message: message,
    }, '_hG6vGmY7qlri5xaB')
      .then((result) => {
        console.log("success",result.text);
        // Handle email sent successfully
      }, (error) => {
        console.log(error.text);
        // Handle email sending error
      });

    // Reset form fields
    setFname('');
    setName2('');
    setEmail('');
    setPhno('');
    setDesig('');
    setMessage('');
    setCompanyname('');
  }

  }
  return (
    <>
      <div>
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {success && <ButtonLink btnPath="/FreeTrialRegistration" />} */}
       

          <div class="row pb-5">
          
                <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12 pb-5 ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="trialformtext">
                      <center>
                        <b>Get In Touch With Us </b>
                      </center>
                    </div>
                    <div class="row">

                      <div class="col-9 col-sm-8 col-lg-6 col-xl-6 col-md-6  pb-3">
                      <center>
                        <InputTextField
                        
                          valueText={fname}
                          inputName="fname"
                          inputId="fname"
                          classname="login-box1"
                          inputType="text"
                          placeholderValue="First name"
                          handleInputChange={handlefirstname}
                          PreventEnter={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                       
                         
                        ></InputTextField>

                        <div className="login-error">{firstnameerror}</div>
                        </center>
                      </div>
                      <div class="col-9 col-sm-8 col-lg-6 col-xl-6 col-md-6 pb-3">
                      <center>
                        <InputTextField
                          handleInputChange={handleChangeLName}
                          valueText={name2}
                          inputName="name2"
                          inputId="name2"
                          classname="login-box1"
                          maxLen={20}
                          inputType="text"
                          placeholderValue="Last Name"
                        ></InputTextField>

                        <div className="login-error">{lastnameerror}</div>
                        </center>
                      </div>



                      <div class="col-9 col-sm-8 col-lg-6 col-xl-6 col-md-9 pb-3">
                      <center>
                        <InputTextField
                          handleInputChange={handleChangeEmail}
                          valueText={email}
                          inputName="email"
                          inputId="email"
                          classname="login-box1"
                       
                          inputType="text"
                          placeholderValue="Email Address"
                        ></InputTextField>
                        </center>
                        <div className="login-error">{emailerror}</div>
                      </div>
                      <div class="col-9 col-sm-8 col-lg-6 col-xl-6 col-md-9 pb-4">
                        <center>
                        <InputTextField
                          handleInputChange={handleChangePhno}
                          valueText={phno}
                          inputName="phno"
                          inputId="phno"
                          classname="login-box1"
                          maxLen={10}
                          inputType="text"
                          placeholderValue="Phone number"

                        ></InputTextField>

                        <div className="login-error">{phnoerror}</div>
                        </center>
                      </div>
                      <div class="col-9 col-sm-8 col-lg-6 col-xl-6 col-md-9 pb-3">
                        <center>
                        <InputTextField
                          handleInputChange={handleChangeDesig}
                          valueText={desig}
                          inputName="desig"
                          inputId="desig"
                          classname="login-box3"
                          
                          inputType="text"
                          placeholderValue="Designation"

                        ></InputTextField>

                        <div className="login-error">{designationerror}</div>
                        </center>
                      </div>
                      <div class="col-9 col-sm-8 col-lg-6 col-xl-6 col-md-9 pb-3">
                    <center>
                        <InputTextField
                         handleInputChange={handleChangeCompanyname}
                         valueText={companyname}
                         inputName="companyname"
                         inputId="companyname"
                         classname="login-box3"        
						  
                         inputType="text"
                         placeholderValue="Company Name"

                        ></InputTextField>

                        <div className="login-error">{companynameerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
<center>
                        <div class="textareamargin">
                        
                          <textarea class="form-control" id="exampleFormControlTextarea4" rows="3" placeholder='Message' onChange={handlemessage}></textarea>
                        </div>
                        </center>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="login-btn"
                          name="submit"
                         disabled={!(firstnameValid && phnoValid && companynameValid && emailValid && designationValid)}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
                <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
            <center>
            <div id="mapimage1" >
    <img src={mappic}  width={600} height={500}/>
            </div>
            </center>
            </div>
              </div>
            </div>
        
    
    </>
  )
}

export default FormPage
