import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Pages/HomePage'
import Header from './Component/Header/Header'
import Features from './Pages/FeaturesPage'
import Plans from './Pages/PlansPage'
import Contactus from './Pages/ContactusPage'
import Footer from './Component/Footer/Footer'
import Loginpage from './Pages/LoginPage'
import GetinTouch from './Pages/RequestDemo'
import Cm from './Component/Cm/cm'
import SchoolPartnership from './Component/SchoolPartnership/SchoolPartnership'
import HomePageBrick from './Component/Brick/HomePageBrick'
// import ContactUsNew from './Component/Contactus/ContactUsNew'
function RoutePage() {
 

  return (
    <>
   
      <Router>
          <Header />
        <Routes>
          <Route path="/" index element={<Plans  />} />
          <Route path="/Aboutus" index element={<Features />} />
          <Route path="/Brick" index element={<HomePageBrick />} />
          <Route path="/Contactus" index element={<Contactus />} />
          <Route path="/Login" index element={<Loginpage />} />
          <Route path="/RequestDemo" index element={<GetinTouch />} />
          <Route path="/construction" index element={<Cm/>} />
          <Route path='/SchoolPartnership' index element={<SchoolPartnership/>}/>
        </Routes>
          <Footer />
      </Router>
    </>
  )
}

export default RoutePage
