import React from 'react'
import Careerimg from '../../assets/images/Careerimg.png'
import Careerbg from '../../assets/images/Careerbg.png'
import { Link } from 'react-router-dom'

import './Career.css'
import { Element } from 'react-scroll'
function Career() {
  return (
    <Element name='Careers' >
      <div class="container">
        <div class="row  ">

          <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 pt-5">
            <div id="box ">
              <center>
                <img className='careerbg mt-0  ' src={Careerbg} alt="not found" />
              </center>
            </div>
            <div id="box"><center><img className='careerimg' src={Careerimg} alt="not found" ></img></center>  </div>
          </div>
          <div class="col-lg-6  col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 ">
            <p className='career1 mt-5 pt-5'>IT Staff Augmentation</p>
            <p className='careertag mt-5'>
              Are you looking to hire talented developers with a specific skill set that your company needs? The top IT staff augmentation company in India, AIS, provides a wide range of hiring options. To add devoted team members with years of experience and skill sets to fulfill the needs of your business, you can use expert IT staff augmentation services.</p>
            <div className='hline'></div>
          </div>
        </div>
      </div>
    </Element>
  )
}
export default Career
