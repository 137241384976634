import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import card1 from '../../assetsS/images/imagecard1.png'
import card2 from '../../assetsS/images/imagecard2.png'

const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid ">
      <div class="row lightgrayback1  textpadspace1 pb-5 pt-5">
        <div class="col-xl-6 col-lg-6 col-md-6 pt-3">
          <div className="newdivision">
            <div id="cardimg">
              <img src={card2} />
            </div>
            <div class="pt-3">
              <p className="headtextsize ">Chapter Wise Testing Practice</p>

              <font className="paratextsize">
                SARASU disciplined testing practice helps to customize NEET test
                practice based on school time schedule and curriculum, enable
                them to evaluate and train their students with the help of the
                software.
              </font>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 pt-3">
          <div className="newdivision">
            <div id="cardimg">
              {' '}
              <img src={card1} />
            </div>
            <div class="pt-3">
              <p className="headtextsize">
                NEET Test Marathon Testing Practice
              </p>

              <font className="paratextsize ">
                Students get unlimited questions within the duration of 1 hour.
                Students can try attempting as much question as possible
              </font>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
