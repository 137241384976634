import React from 'react'
import banner from '../../assets/images/sarasu12345.png'
import art1 from '../../assets/images/Art2.png'

import './Artificial.css';

import { Link } from 'react-router-dom'
function Projectprogress() {

  return (
    <div className=" container-fluid  "style={{ position: 'relative', padding: 0 }}>
    <div class='container-fluid bannerai1 '> 
       <div> <p className='h4 ' style={{fontWeight:'lighter'}}data-aos="fade-left"
     data-aos-duration="3000">Transforming Future</p></div>
    <center>  <p className='container  p1   ' data-aos="fade-up"
     data-aos-duration="3000">AIS  MAKES  ARTIFICIAL 
INTELLIGENCE  FOR  TOMORROW</p></center>
     
</div> 
        </div>
        
  )
}
export default Projectprogress
