import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/stage.png'
import Samplevideo from '../../assetsS/Videos/biologychampionleaguereformat.mp4'
import image69 from '../../assetsS/firstcomponent/biologythumnail.jpg'

const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid lightgrayback1">
      <video
        controls
        width="100%"
        poster={image69}

        //={false}
      >
        <source src={Samplevideo} type="video/mp4" />
      </video>
    </div>
  )
}

export default Gallery
