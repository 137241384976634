import React from 'react'
// import Background from '../../assets/images/productimg.png'
import './ourProduct.css'
import background from '../../assets/images/Abimg.png';
import { Element, Link } from 'react-scroll';

function OurProducts() {
  return (
    <>
      <div className='bg-white p-1 w-100 position-fixed top-25 d-none d-md-block ' style={{ zIndex: "999" }}>
        <ul className='d-flex justify-content-around list-unstyled w-100 mb-0 p-1' style={{ cursor: "pointer" }}>
          <li className='nav-item'> <Link activeClass="active" spy={true} smooth={true} offset={-158}
            duration={500} to="Advantages" className="nav-link">The Athees Info Advantages</Link></li>
          {/* <li className=''></li> */}
          <li className='nav-item'><Link activeClass="active" spy={true} smooth={true} offset={-100}
            duration={500} to="Services" className="nav-link">Our Services</Link></li>
          <li className='nav-item'><Link activeClass="active" spy={true} smooth={true} offset={-100}
            duration={500} to="Careers" className="nav-link">IT Staff Augmentation</Link></li>
          <li className='nav-item'><Link activeClass="active" spy={true} smooth={true} offset={-100}
            duration={500} to="Technologies" className="nav-link">Technologies</Link></li>
        </ul>
      </div>
      <Element name='home'>
        <div className='' style={{ height: "100vh", backgroundImage: `url(${background})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",backgroundSize:"cover", }}>
          <div className='text-center ' style={{position:"absolute",top:"40%"}} >
            <h1 className='text-uppercase text-white pt-5 mb-0' id='home' data-aos="fade-up"
              data-aos-duration="3000">construction project management Solutions for Global Construction Markets
            </h1>
            <p data-aos="fade-up"
              data-aos-duration="3000" className='text-capitalize text-white w-50 mx-auto mt-3' style={{fontSize:"17px"}}>We provide global solutions for infrastructure project managent which involves managing work methodology, procedure, site update, BOQ,  real location integration by mapping.
            </p>
          </div>
        </div>
      </Element>
    </>
  )
}

export default OurProducts
