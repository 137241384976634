import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/cup.png'
import plus from '../../assetsS/images/plus.png'
import minus from '../../assetsS/images/minus.png'
const Gallery = () => {
  const [ques1, setQues1] = useState(false)
  const [ques2, setQues2] = useState(false)
  const [ques3, setQues3] = useState(false)
  const [ques4, setQues4] = useState(false)
  const [ques5, setQues5] = useState(false)

  useEffect(() => {})
  function updateques1() {
    setQues1(!ques1)
  }
  function updateques2() {
    setQues2(!ques2)
  }
  function updateques3() {
    setQues3(!ques3)
  }
  function updateques4() {
    setQues4(!ques4)
  }
  function updateques5() {
    setQues5(!ques5)
  }
  return (
    <div class="container-fluid ">
      <div class="row lightgrayback1  textpadspace1 pb-5 pt-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-3 pb-5">
          <div>
            <font className="bigtextfaq">&nbsp;&nbsp; FAQS</font>
          </div>
          <div className="newdivision pb-5">
            <div className="faqdivision mb-4">
              <font className="headtextsizefaq">
                {' '}
                What is a SARASU NEET Online Testing Practice?{' '}
              </font>{' '}
              &nbsp;
              {ques1 ? (
                <button class="btn" onClick={() => updateques1()}>
                  {' '}
                  <img src={minus} height={20} width={20} />
                </button>
              ) : (
                <button class="btn" onClick={() => updateques1()}>
                  <img src={plus} height={20} width={20} />
                </button>
              )}
            </div>
            {ques1 && (
              <div className="allsidespace">
                <font className="paratextsize1 ">
                  Sarasu NEET Online testing practice software simplifes the
                  NEET MCQ's testing & evaluation process for teachers. It also
                  provides the customized subject wise testing practise
                </font>
              </div>
            )}

            {/* */}
            <div className="faqdivision mb-4">
              <font className="headtextsizefaq">
                {' '}
                What difference SARASU makes in teaching institutes?
              </font>{' '}
              &nbsp;
              {ques2 ? (
                <button class="btn" onClick={() => updateques2()}>
                  {' '}
                  <img src={minus} height={20} width={20} />
                </button>
              ) : (
                <button class="btn" onClick={() => updateques2()}>
                  <img src={plus} height={20} width={20} />
                </button>
              )}
            </div>
            {ques2 && (
              <div className="allsidespace">
                <font className="paratextsize1 ">
                  Manual evaluation of the students capabilities in facing NEET
                  MCQ's becomes impossible. SARASU provides online solution to
                  this. Once after students complete the test immediate results,
                  with capability analysis reports of each students will be
                  provided. This gives the details of the avg time spend on each
                  questions chapterwise, avg time taken to attend twisted
                  questions & direct question, the stamina of the student
                  through out the exam in graphical representation X Axis: No of
                  questions attended per 10 mins & Y Axis: Minutes. These helps
                  to understand where exactly students struggle and train &
                  improve the students focusing on the weeknessess
                </font>
              </div>
            )}

            {/*  */}

            {/* */}
            <div className="faqdivision mb-4">
              <font className="headtextsizefaq">
                {' '}
                Can the testing practice & evaluation can be customized?{' '}
              </font>{' '}
              &nbsp;
              {ques3 ? (
                <button class="btn" onClick={() => updateques3()}>
                  {' '}
                  <img src={minus} height={20} width={20} />
                </button>
              ) : (
                <button class="btn" onClick={() => updateques3()}>
                  <img src={plus} height={20} width={20} />
                </button>
              )}
            </div>
            {ques3 && (
              <div className="allsidespace">
                <font className="paratextsize1 ">
                  Teachers get the admin login where respective teachers can
                  assign unlimited NEET MCQ's test based on the chapters
                  covered. Can also assign Mock test.{' '}
                </font>
              </div>
            )}

            {/*  */}

            {/* */}
            <div className="faqdivision mb-4">
              <font className="headtextsizefaq">
                What is the cost of SARASU Institute NEET Testing practice
                software?{' '}
              </font>{' '}
              &nbsp;
              {ques4 ? (
                <button class="btn" onClick={() => updateques4()}>
                  {' '}
                  <img src={minus} height={20} width={20} />
                </button>
              ) : (
                <button class="btn" onClick={() => updateques4()}>
                  <img src={plus} height={20} width={20} />
                </button>
              )}
            </div>
            {ques4 && (
              <div className="allsidespace">
                <font className="paratextsize1 ">
                  The major struggle of the students are facing MCQ's, because
                  students are used to theory based exams. Hence adopting
                  students to face MCQ's is challenging. SARASU provides
                  solution for this with ₹800 per student per month, which is a
                  maximum of about 10,000 annually.
                </font>
              </div>
            )}

            {/*  */}

            {/* */}
            <div className="faqdivision mb-4">
              <font className="headtextsizefaq">
                Is SARASU ideal for NEET competitive exam teaching institutes?
              </font>{' '}
              &nbsp;
              {ques5 ? (
                <button class="btn" onClick={() => updateques5()}>
                  {' '}
                  <img src={minus} height={20} width={20} />
                </button>
              ) : (
                <button class="btn" onClick={() => updateques5()}>
                  <img src={plus} height={20} width={20} />
                </button>
              )}
            </div>
            {ques5 && (
              <div className="allsidespace">
                <font className="paratextsize1 ">
                  SARASU provides Speed Trainer - Improves students speed &
                  accuracy in competitive exam, Test marathon provides access to
                  customized unlimited test practice. Master the biology
                  terminologies using Biology Champian league testing practice
                </font>
              </div>
            )}

            {/*  */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
