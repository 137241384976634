import React from 'react'
import './CoreValues.css'
import dgimg from '../../assets/images/dtimg.png'
import ic2 from '../../assets/images/Icons_and_images/professional team.png'
import ic3 from '../../assets/images/Icons_and_images/trusted by clients.png'
import ic4 from '../../assets/images/Icons_and_images/comitted to delivery.png'


import { Link } from 'react-router-dom'
function CoreValues() {
  return (
    <div class="  ">
    <div class="row pb-5 pt-5">
      
       <div class="col-lg-11 col-xl-11 col-md-12 col-sm-12 col-12">
       <div> 
        <center><img src={dgimg} alt="not found" width={1360} height={620} className="dtimg1" />
        </center></div>
        <div class="col-xl-5"></div>
        </div><div class="col-lg-9 col-xl-9 col-md-12 col-sm-12 col-12">
        <div className='rectanglea'></div><p className='dttaglinea'> Online testing Practice center of Excellence for NEET 
        Online testing Practice center of Excellence for NEET Online testing Practice center of Excellence for NEET
        Online testing Practice center of Excellence for NEET
       </p> 
       </div>
       
        </div>
        </div>

  )
}
export default CoreValues
