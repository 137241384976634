import React, { useState } from 'react';
import icon1 from '../assets/images/Icons_and_images/manage multiple project.png';
import icon2 from '../assets/images/Icons_and_images/labour_attendance.png';
import icon3 from '../assets/images/Icons_and_images/site reporting.png';
import icon4 from '../assets/images/Icons_and_images/task and issues.png';
import icon5 from '../assets/images/Icons_and_images/manage budgeting.png';
import icon6 from '../assets/images/Icons_and_images/material request.png';
import './ProjectManagement.css'
const Projectprogress = ({}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-xl-12"></div>
        <div className="col-lg-2 col-xl-2 "></div>
        <div className={`col-lg-3 col-xl-3 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className="rounded division9">
            <div className="small-division9">
              <div className="icon-and-number9">
                <img
                  src={isHovered ? require('../assets/images/Icons_and_images/manage_multiple_project_hover.png') : icon1}
                  width="35px"
                  height="35px"
                  alt="not found"
                  className="iconpad9"
                />
                <font className="divitxt9">01</font>
              </div>
            </div>
            <br></br>
            <div className="headingfont9">
              <h5> Manage
                <br></br> Multiple projects</h5>
            </div>
          </div>
        </div>

        <div className={`col-lg-3 col-xl-4 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className="rounded division9">
            <div className="small-division9">
              <div className="icon-and-number9">
              <img
                  src={isHovered ? require('../assets/images/Icons_and_images/labour attendance hover.png') : icon2}
                  width="35px"
                  height="35px"
                  alt="not found"
                  className="iconpad9"
                />             
              <font className="divitxt9">02 </font>
              </div>
            </div>
            <br></br>
            <div className="headingfont9">
              <h5>Labour Attendance
                <br></br> and report</h5>
            </div>
          </div>
        </div>

        <div className={`col-lg-3 col-xl-3 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className="rounded division9">
            <div className="small-division9">
              <div className="icon-and-number9">
              <img
                  src={isHovered ? require('../assets/images/Icons_and_images/task and issues hover.png') : icon3}
                  width="35px"
                  height="35px"
                  alt="not found"
                  className="iconpad9"
                />                 
                 <font className="divitxt9">03</font>
              </div>
            </div>
            <br></br>
            <div className="headingfont9">
              <h5> Site
                <br></br> Reporting</h5>
            </div>
          </div>
        </div>

        <div className={`col-lg-2 col-xl-2 mg2 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}></div>

        <div className={`col-lg-3 col-xl-3 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className="rounded division9">
            <div className="small-division9">
              <div className="icon-and-number9">
              <img
                  src={isHovered ? require('../assets/images/Icons_and_images/site reporting hover.png') : icon4}
                  width="35px"
                  height="35px"
                  alt="not found"
                  className="iconpad9"
                /> 
                <font className="divitxt9">04</font>
              </div>
            </div>
            <br></br>
            <div className="headingfont9">
              <h5>Tasks 
                <br></br>and Issues</h5>
            </div>
          </div>
        </div>

        <div className={`col-lg-3 col-xl-4 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className="rounded division9">
            <div className="small-division9">
              <div className="icon-and-number9">
              <img
                  src={isHovered ? require('../assets/images/Icons_and_images/manage budgeting hover.png'): icon5}
                  width="35px"
                  height="35px"
                  alt="not found"
                  className="iconpad9"
                /> 
                <font className="divitxt9">05</font>
              </div>
            </div>
            <br></br>
            <div className="headingfont9">
              <h5>Managing Budgeting
                 and BoQ</h5>
            </div>
          </div>
        </div>

        <div className={`col-lg-3 col-xl-3 ${isHovered ? 'hovered' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <div className="rounded division9">
            <div className="small-division9">
              <div className="icon-and-number9">
              <img
                  src={isHovered ? require('../assets/images/Icons_and_images/material request hover.png'): icon6}
                  width="35px"
                  height="35px"
                  alt="not found"
                  className="iconpad9"
                />      <font className="divitxt9">06</font>
              </div>
            </div>
            <br></br>
            <div className="headingfont9">
              <h5>Material Request 
                <br></br>and Purchases</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projectprogress;
