
import WeareHere from '../Component/WeareHere/WeareHere';
import HomeBanner from '../Component/FirstBanner/Welcometobrick';
import AIinfo from '../Component/Aiinfo/aiinfo';
import Letstalk from '../Component/tran/tran';

import Mission from '../Component/MisionVision/Mission'
import Vission from '../Component/MisionVision/Vision'



function HomePage() {
  return (
    <div>
     <HomeBanner pagename="About Us"/>
     <AIinfo />
     <Letstalk/>
     
     {/* <Vission />
     <Mission />  */}
        
{/*     
<WeareHere pagename="Home"/> */}
    </div>
  );
}

export default HomePage;
