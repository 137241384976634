import React from 'react'
import Aboutusimg from '../../assets/images/Aboutusimg.png'
import Aboutusbg from '../../assets/images/Aboutusbg.png'
import { Link } from 'react-router-dom'

import './ImageandText.css'
function Projectprogress() {
  return (
    <div class="container">
    <div class="row  padfull2">
       <div class="col-lg-6  col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 ">
        <p className='aboutus'>About Us</p>
        <p className='abouttag'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <div className='hline'></div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 col-12 pt-5">
        <div id="box">
            <center>
                <img className='aboutusbg' src={Aboutusbg} alt="not found" />
            </center>
        </div>
       <div id="box"><center><img className='aboutusimg' src={Aboutusimg} alt="not found" ></img></center>  </div>
       </div>
        </div>
        </div>
  )
}
export default Projectprogress
