import React from 'react'
import './SchoolPartnership.css'
import icon1 from '../../assetsS/images/one.png'
import icon2 from '../../assetsS/images/two.png'
import Photo1 from '../../assetsS/images/back1.png'
export default class TextonImage extends React.Component {
  state = {}

  updateDetails() {
    this.props.handleStateChange(this.state)
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps)
  }

  componentDidMount = async () => {
    this.setState(this.props)
  }

  render() {
    const isBgImg = this.state.isBgImg

    return (
      <div
        className="homebannerimgcomp111new"
        style={{ backgroundImage: `url(${Photo1})` }}
      >
        <div class="text-overlaycomp7">
          <div class="container-image">
            <div class="row biochamppad2">
              <div class="col-xl-1 col-lg-1 col-xxl-1 col-md-2 col-sm-2 col-2 pb-3">
                <img className="imagei" src={icon1} />
              </div>
              <div class="col-xl-11 col-lg-11 col-xxl-11 pb-3 textpadtop1 col-md-10 col-sm-10 col-10">
                <font className="blueboxhead1comp7">
                  Each teacher can log in the list of chapter that they teach
                  for the week and generate test based on that and assign it to
                  student
                </font>
              </div>

              <div class="col-xl-1 col-lg-1 col-xxl-1 col-md-2 col-sm-2 col-2">
                <img className="imagei" src={icon2} />
              </div>
              <div class="col-xl-11 col-lg-11 col-xxl-11 textpadtop col-md-10 col-sm-10 col-10">
                <font className="blueboxhead1comp7 ">
                  Reduce time and effort put in for the answer paper correction
                </font>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
