import React, { useEffect, useState } from 'react'
import Photo1 from '../../assetsS/images/back3.png'

import './SchoolPartnership.css'

const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid">
      <div
        className="homebannerimgcomp5"
        style={{ backgroundImage: `url(${Photo1})` }}
      >
        <div class="text-overlay2comp5">
          <div>
            <font className="bigtextcomp22">
              <center>Biology Champion League </center>
            </font>
            <font className="bigtextcomp22">
              <center>
                <font className="colortext2comp22">NEET&nbsp;</font>
                Testing Practice
              </center>
            </font>
          </div>
        </div>
      </div>
      {/*  */}

      {/*  */}
    </div>
  )
}

export default Gallery
