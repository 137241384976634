import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import './ourProduct.css'
import school_video from '../../assets/images/Icons_and_images/schoolpartnervideo.mp4'

function ProductVideos() {
    return (
        <>
            <div className='container-fluid  bg-white p-5 pt-5 mt-5 '>
                <div className='row gap-2'>
                    <div className='col-12 col-lg-6 video-c shadow rounded p-3 order-last order-lg-3'>
                        <div className='container'>
                            <div className='row '>
                                <div className='col-12 mt-5'>
                                    <h6>Partnering with schools for testing practice</h6>
                                    <span className='text-muted' style={{ fontWeight: "500" }}>Sarasu Provides</span>
                                    <ol className='mt-3 pl-3' style={{ fontSize: "14px" }}>
                                        <li>Exclusive NEET Testing Practice Progress Tracker </li>
                                        <li>Automated NEET Test Assessment </li>
                                        <li>Cloud-Based NEET Online Study Tips & Solutions</li>
                                    </ol>
                                    <p className='text-justify' style={{ fontSize: "14px" }}>SARASU NEET Testing Practice cloud-based test management platform app that manages your testing and evaluation process of your students who are Doctor aspirants.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 p-3 text-center order-3 order-lg-last  '>
                        <video width="500" height="240" className='rounded shadow-lg img-fluid' controls loop>
                            <source src={school_video} type="video/mp4" className='' />
                        </video>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductVideos
