import React, { useEffect, useState } from 'react'
import './SchoolPartnership.css'
import icon from '../../assetsS/images/fa-solid_school.png'
const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid pt-2">
      <div className="textpadspace1">
        <font className="bigtextblack ">
          <center>
            What are the specifications of{' '}
            <font className="colortext1">SARASU NEET</font> Online Testing
            Practice Software?
          </center>
        </font>
      </div>
      <div class="row pt-5 pb-5">
        <div class="col-md-0 col-lg-0 col-xl-1 col-sm-0 col-0"></div>
        <div class="col-md-12 col-lg-12 col-xl-10 col-sm-12 col-12">
          <table class="table table-bordered normaltextblack1 bluebackcolor">
            <thead>
              <tr>
                <th
                  className="tabalign"
                  scope="col"
                  style={{ backgroundColor: '#1579C5', color: 'white' }}
                >
                  Specifications
                </th>
                <th
                  className="tabalign"
                  scope="col"
                  style={{
                    width: '150px',
                    backgroundColor: '#1579C5',
                    color: 'white',
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  Assign unlimited test to any number of students just by 1
                  click
                </th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">Subject wise teacher admin login dashboard</th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">Answers , Explanations, Concept details</th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Automatic test results in stunning dashboards
                </th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">Terminology Training</th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">Answering Speed & Accuracy Training</th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">OMR sheet upload and evaluation</th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>

              <tr>
                <th scope="row"> Customised Testing practice</th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  {' '}
                  Capability analysis report: MCQ's answering speed of the
                  students every 10 minutes in the exam is monitored
                </th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  {' '}
                  Avg time taken by students chapter-wise, direct question and
                  indirect twisted questions are measured
                </th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {' '}
                  AI based system formulated the customized test practice for
                  students based on the capability analysis report
                </th>
                <td>
                  <center>Yes</center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-0 col-lg-0 col-xl-1 col-sm-0 col-0"></div>
      </div>
    </div>
  )
}

export default Gallery
