// import WeareHere from '../Component/WeareHere/WeareHere';
// import HomeBanner from '../Component/FirstBanner/Welcometobrick';
// import Plans from '../Component/Plans/Plans';
import Career from '../Component/Career/Career';
import ProjectManagement from '../Component/ProjectManagement/ProjectMgnt';
import Technology from '../Component/Technology/Technology';
// import Solutionspage from '../Component/solutions/solution';
// import Solutionproductpage from '../Component/Productpage/productpage';
import OurProducts from '../Component/ourProducts/OurProducts';
import Solutions from '../Component/ourProducts/Solutions';
import ProductVideos from '../Component/ourProducts/productVideos'

function PlanPage() {
  return (
    <div style={{backgroundColor: "#e8e1f0"}}>
      <OurProducts />      
      <Solutions />
      <ProductVideos />
      <ProjectManagement/>
      <Career/>
      <Technology/>
    </div>
  );
}

export default PlanPage;
