import React from "react";
import "./contactus.css";
import ctimg from "../../assets/images/Contactus.png";
import ctbg from "../../assets/images/ctd.png";
import ctright from "../../assets/images/ct right.png";
import ic2 from "../../assets/images/Icons_and_images/professional team.png";
import ic3 from "../../assets/images/Icons_and_images/trusted by clients.png";
import ic4 from "../../assets/images/Icons_and_images/comitted to delivery.png";
import { Link } from "react-router-dom";

function Contactuspage() {
  const containerStyle = {
    backgroundImage: `url(${ctbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "60vh",
  };

  return (

    <div className="container-fluid " style={{ position: 'relative', padding: 0 }}>
      <div className="container-fluid ctus1 " >
        <center><p className="ctus " data-aos="fade-up"
          data-aos-duration="3000">CONTACT US </p></center>
      </div>
      <div className="row" >
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12 col-xs-12 ">
          <div>
            <center>
              <h1 className="oursolutions text-primary mt-5" style={{ fontSize: "" }}>
                OUR <br />
                LOCATIONS
              </h1>
            </center>
          </div>

        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12 col-xs-12">
          <div>

            <h1 className="madurai">MADURAI</h1>
            <p className="ad1">
              No 62, KK Nagar <br></br>
              Madurai - 625020, India,<br />
              Ph.no: 7894561230, <br />
              7894561230
            </p>


            <a className="button"
              href="https://www.google.com/maps/dir//X46M%2BW3R,+Vishwa+Shanthi+Nagar,+Narayanapuram,+Iyer+Bungalow,+Madurai,+Tamil+Nadu+625014/@9.9623569,78.0502518,
          12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b00c730450054d5:
          0x59b581274bf175af!2m2!1d78.1326537!2d9.962367?entry=ttu"
            >
              Get Directions
            </a>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12 col-xs-12">
          <div>

            <h1 className="madurai">KARNATAKA</h1>
            <p className="ad1">
              SARASU
              #7, 100ft.Ring Road 4th Block,
              3rd Phase BSK 3rd Stage
              Bengaluru-560 085.
            </p>
<br/>

            <a className="button"
              href="https://maps.app.goo.gl/CuUnT1U27uRHrNaeA"
            >
              Get Directions
            </a>
          </div>
        </div>
        <div className="col-md-8 col-12 pl-3 ml-auto">
          <div className="">

            <h1 className="madurai">COIMBATORE</h1>
            <p className="ad1">
              Address: at No.36/1, Raghav Tower, Amman Nagar, Saravanampatti, Coimbatore -641035 Tamil Nadu, INDIA.<br /> Phone Number: +91– 7339110765 <br /> Email:info@smartproservice.com
            </p>


            {/* <a className="button"
              href="https://www.google.com/maps/dir//X46M%2BW3R,+Vishwa+Shanthi+Nagar,+Narayanapuram,+Iyer+Bungalow,+Madurai,+Tamil+Nadu+625014/@9.9623569,78.0502518,
          12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b00c730450054d5:
          0x59b581274bf175af!2m2!1d78.1326537!2d9.962367?entry=ttu"
            >
              Get Directions
            </a> */}
          </div>
        </div>

        {/* <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12 col-xs-12">
          <div>
            
              <h1 className="madurai">Ph.no</h1>
              <p className="ad1">
              
              </p>
            
            
          <a className="button2"
              href="https://www.google.com/maps/dir//X46M%2BW3R,+Vishwa+Shanthi+Nagar,+Narayanapuram,+Iyer+Bungalow,+Madurai,+Tamil+Nadu+625014/@9.9623569,78.0502518,
            12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3b00c730450054d5:
            0x59b581274bf175af!2m2!1d78.1326537!2d9.962367?entry=ttu"
            >
              Call
            </a>
          </div>
        </div> */}

      </div>
    </div>
  );
}

export default Contactuspage;
