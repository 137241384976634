import React from 'react';
import './Footer.css';
import locationbottom from '../../assets/images/Icons_and_images/location bottom.png';
import mobilebottom from '../../assets/images/Icons_and_images/mobile bottom.png';
import timebottom from '../../assets/images/Icons_and_images/time bottom.png';
import mail from '../../assets/images/Icons_and_images/mail.png';
import facebook from '../../assets/images/Icons_and_images/facebook.png';
import whatsapp from '../../assets/images/Icons_and_images/whatsapp.png';
import firstf from '../../assets/images/Icons_and_images/image-1.jpg';
import secondf from '../../assets/images/Icons_and_images/project-2.jpg';
import thirdf from '../../assets/images/Icons_and_images/project-3.jpg';
import fourth from '../../assets/images/Icons_and_images/project-1.jpg';
import bricklogo from '../../assets/images/Icons_and_images/logo.jpg';
import sarasulogo from '../../assets/images/SARASU_LOGO_SVG.svg';
import AIS1 from '../../assets/images/AIS Logo.png';
import Accordion from 'react-bootstrap/Accordion';
import '@fontsource/manrope';
import '@fontsource/syne';

function Footer() {
  return (
    <>
      <div className=" d-flex justify-content-evenly align-items-center border-top">


        <img src={AIS1} alt="not found" width={150} height={180} align="left" className="img-fluid" />







        <ul className=" list-unstyled ml-auto mr-5 ">


          <li className=''><a href="/" style={{ color: 'black' }}>Home</a></li>
          {/* <li className='mt-2'><a href="/Services" style={{ color: 'black' }}>Our Products</a></li> */}
          <Accordion className='bg-transparent'>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Our Products</Accordion.Header>
              <Accordion.Body>
                <ul className='pl-4'>
              <li className=''><a href="/Brick" style={{ color: 'black' }}>Brick</a></li>
              <li className='mt-2'><a href="/SchoolPartnership" style={{ color: 'black' }}>Sarasu</a></li>
              </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <li className='mt-2'><a href="/Contactus" style={{ color: 'black' }}>Contact Us</a></li>
        </ul>


      </div>

      <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 text-white " style={{ backgroundColor: "#31299e", position: "absolute", bottom: "" }}>
        <div className='p-2 text-center'>
          <center>
            <p >
              © 2024 Athees Info Solutions, Inc.All rights reserved
            </p></center>

        </div>
      </div>
    </>

  );
}

export default Footer;
