import ProjectMgnt from "../BlurImageBackground/ProjectManagement";
import Projectprogress from "../LeftImageRightText/ProjectProgress";
import TextonImageBrick from "../Brick/TextonImageBrick/TextonImage";
import WeareHereBrick from "../Brick/WeareHere/WeareHereBrick";
import { useState, useMemo } from "react";

import Partners from "./Partners/Partners";


function HomePage() {
  return (
    <div class="container-fluid">
     <TextonImageBrick />
     <Partners/>
     <Projectprogress />
     <ProjectMgnt/>
     {/* <CoreValues/> */}
     <WeareHereBrick pagename="Home"/>
    </div>
  );
}

export default HomePage;

