import React from 'react'
import banner2 from '../../assets/images/Icons_and_images/Banner-bg.jpg'
import banner from '../../assets/images/Icons_and_images/banner_image.png'
import card1 from '../../assets/images/Icons_and_images/a1.png'
import card2 from '../../assets/images/Icons_and_images/a2.jpg'
import card3 from '../../assets/images/Icons_and_images/a3.png'
import iconimgst from '../../assets/images/Icons_and_images/business_owners.png'
import iconimgst1 from '../../assets/images/Icons_and_images/project planning.png'
import iconimgst2 from '../../assets/images/Icons_and_images/site engineers.png'

import { Link } from 'react-router-dom'
import './TextonImage.css'

function TextonImage() {
  return (
    <div class="container-fluid">
      <div
        class="textOverImgcontactus"
        style={{ backgroundImage: `url(${banner2})`} }
      >
        {/* <div class="row pt-4">
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
            <div className='padall'>
              <p className='bluehead44'><u >Welcome to Athees Info Solutions</u></p>
              <p className='blackhead67'>Track Progress on your fingertips</p>
           

              <Link to='/RequestDemo'>
                <button type="button" className="btncolor">
                  <b>Request Demo </b>

                </button>
              </Link>
            </div>
          </div>
          <div class="col-xl-7  col-lg-7 col-md-7 col-sm-12 col-12 pt-2 ">
            <img  className='phoneimage'src={banner} alt="not found"  />
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
            <p className='bluediv1'>How does it works?</p>
            <div class="boxmargin">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="iconDiv1">
                    <div style={{ backgroundImage: `url(${card1})` }} class="textcardimage">


                      <div class="text-block" >
                      <center>
                        
                        <font className='boxinsidetextheading0'>AI Services  </font> 
                          <br></br>  <font  className='boxinsidetextheading0'>   </font>  </center>
                        <center>   <p >A brick is type 
                             construct 
                             <br></br>material
                             used build walls. </p>
                             </center>
                        <div class="iconmargin">
                          <center><img  className='iconim'src={iconimgst}width={60} /></center>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="iconDiv1">
                    <div style={{ backgroundImage: `url(${card2})` }} class="textcardimage">

                      <div class="text-block">
                      <center>
                      <font className='boxinsidetextheading0'>Project  plan</font>
                        <br></br>  <font className='boxinsidetextheading0'>Manager</font>
                        <p>A brick is  type  construct 
                        <br></br>material
                           used  build walls. </p>
                           </center>
                        <div class="iconmargin">
                          <center><img className='iconim' src={iconimgst1} width={60} /></center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                 
                  <div class="iconDiv1">
                    <div style={{ backgroundImage: `url(${card3})` }} class="textcardimage">

                      <div class="text-block">
                      <center>
                      <font className='boxinsidetextheading0'>Site Engineer  </font>
                          <br></br><font className='boxinsidetextheading0'>    Supervisor
                        </font>
                        <p > A brick is  type construct 
                        <br></br>material
                           used  build walls. </p>
                           </center>
                        <div class="iconmargin">
                          <center><img className='iconim' src={iconimgst2} width={60} /></center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div>
        </div>
      </div>
    </div>

  )
}
export default TextonImage
