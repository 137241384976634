import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import './LoginForm.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map.jpg'
import { AiFillEye } from 'react-icons/ai'
//import logo1 from '../../assets/images/sarasu-logo1.svg'
// import logo1 from '../assets/images/sarasu-logo1.svg'
// import whatsapp from '../assets/images/whatsapp.jpg'
// import email1 from '../assets/images/email.png'
// import axios from 'axios'
// import { baseurl } from '../Component/BaseUrl/Baseurl'
// import ButtonLink from '../Component/ButtonLink/ButtonLink'
function Login() {
 

    const [isPasswordShown1, setIsPasswordShown1] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [emailerror, setEmailerror] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [passworderror, setPassworderror] = useState('')

      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
     
      }, [])

   
 
      const togglePasswordVisiblity1= (event) => {
        setIsPasswordShown1(!isPasswordShown1 )
        event.preventDefault()
      }

      function onemailChange(e) {
   
        setEmail(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
      }

      function onpasswordChange(e) {
   
        setPassword(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
      }
      const strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
      )

      const validateField = (fieldName, value) => {
  
        if (fieldName === 'password') {
          let passwordValid = value != '' && value.match(strongRegex)
    
       
          setPasswordValid(passwordValid)
        
          if (passwordValid === null || passwordValid === undefined) {
            setPassworderror(
              'Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character'
            )
            setPasswordValid(false)
          } else {
            setPassworderror('')
            setPasswordValid(true)
          }
        }


       
        if (fieldName === 'email') {
            const regex =
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          
           
            let emailValid = value != '' && value.match(regex)
            console.log(emailValid)
         
            setEmailValid(emailValid)
          
            if (emailValid === null || emailValid === undefined) {
              setEmailerror(
                'Please Enter your valid Email'
              )
              setEmailValid(false)
            } else {
              setEmailerror('')
              setEmailValid(true)
            }
          }
    }
    function onSubmitEvent(event) {
        event.preventDefault()
       
    
        // alert(email +"***"+password)
        window.location.pathname = '/Login'
        if (emailValid && passwordValid) {
            console.log(email)
            console.log(password)

            //   let userData = new FormData()
            //   userData.append('name', name)
            //   userData.append('email', '')
            //   userData.append('phone_no', phno)
            //   axios
            //     .post(baseurl + '/api/freeTrialMarketing', userData, {
            //       headers: { 'Content-Type': 'multipart/form-data' },
            //     })

            //     .then((response) => {
            //       setSuccess(true)
            //     })
            //     .catch((error) => {
            //       // console.log(error)
            //       setErrormsg(error.response.data.message)
            //     })
        }
    }
    return (
        <>
            <div>
                {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
                {/* {success && <ButtonLink btnPath="/FreeTrialRegistration" />} */}

                <div class="row pb-5 pt-5">
                    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pb-5 ">
                        <div class="trialformtext">
                            <center>
                                <b>Login into account </b>
                            </center>
                        </div>
                        <p> <center>
                            use your credentials to access your account
                        </center></p>

                        <form
                            class="row d-flex justify-content-center align-center pb-4 "
                            // onSubmit={onSubmitEvent}
                        >

                            <div class="col-12 col-sm-12 col-lg-11 col-md-12 pt-4">

                                <InputTextField
                                       handleInputChange={onemailChange}
                                    //     onKeydown={Loginspace}
                                    valueText={email}
                                    inputName="email"
                                    inputId="email"
                                    classname="login-box2"
                                    inputType="text"
                                    placeholderValue="Email Address"
                                    PreventEnter={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                      }}
                                ></InputTextField>

                                <div className="login-error">{emailerror}</div>
                            </div>
                            <div class="col-12 col-sm-12 col-lg-11 col-md-12 pt-3">

                                <InputTextField
                                     handleInputChange={onpasswordChange}
                                    valueText={password}
                                    inputName="password"
                                    inputId="password"
                                    classname="login-box2"
                                    maxLen={16}
                                    placeholderValue="Password"
                                    inputType={isPasswordShown1  ? 'text' : 'password'}
                                    PreventEnter={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                      }}
                                ></InputTextField>
                                {isPasswordShown1 ? (
                        <i
                          class="bi bi-eye demo-spa"
                          onClick={togglePasswordVisiblity1}
                        ></i>
                      ) : (
                        <i
                          class="bi bi-eye-slash demo-spa"
                          onClick={togglePasswordVisiblity1}
                        ></i>
                      )}
                                <div className="login-error">
                                    {passworderror}

                                </div>
                            </div>

                            <div class="pt-4 pb-4 col-12 col-sm-12 col-md-6 col-lg-6">
                                <center>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                                </div>
                                </center>
                            </div>

                            <div class="pt-4 pb-4 col-12 col-sm-12 col-md-6 col-lg-6">
                                <center>
                                    <a href="/" className="login-ptn">
                                        Forgot password?
                                    </a>
                                </center>
                            </div>

                            <div class="col-sm-12 col-lg-12 col-12 col-md-12">
                                <center>
                                    <button type="submit" className="login-btn" name="submit" onClick={onSubmitEvent}>
                                        <b>LOGIN TO YOUR ACCOUNT</b>
                                    </button>
                                </center>{' '}
                            </div>
                            <div class="col-sm-12 col-lg-12 col-12 col-md-12 pt-3 ">
                  <center>
                  If you don't have an account? <a href="/Registration" class="login-lnk">
                     <u> Register Now</u>
                    </a>
                   
                  </center>
                </div>

                        </form>
                    </div>

                </div>
            </div>




        </>
    )
}

export default Login
