import './App.css';
import Route from './route'
import "@fontsource/manrope"; 
import "@fontsource/syne";
function App() {
  return (
    <div>
     <Route />
    </div>
  );
}

export default App;
