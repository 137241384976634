import RequestDemo from '../Component/RequestDemo/RequestDemo';

function PlanPage() {
  return (
    <div>
     <RequestDemo/>  
   
    </div>
  );
}

export default PlanPage;
