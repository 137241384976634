import React from "react";
import "./letstalk.css";
import ltimg from "../../assets/images/ltbg.png";
function Letstalkpage() {
  return (
    <div class="  ">
      <div class="row ">
        
        {/* <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
          
        </div> */}
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12">
          <div>
            <center>
              <img
                src={ltimg}
                alt="not found"
                width={1360}
                height={420}
                className="ltimg"
              />
            </center>
          </div>
          <div class="container">
          <div className="lttag">
          <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12">
        <center>
              <p>LET'S TALK TO TRANSFORM YOUR BUSINESS</p>
              
            </center>
          </div>
          </div>
          <div className="lttag1">
          <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12" >
        <center>
              <p>Start the journey of transforming your business with a 15-minute meeting</p>
              <a className="button3" href="/RequestDemo">📞 Setup a Call</a>
              
            </center>
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Letstalkpage;
