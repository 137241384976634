import React from 'react';
import backgnd from '../../assets/images/About us.png';
import { Link } from 'react-router-dom';
import './Welcometobrick.css'; // Import your CSS file

const WeareHere = ({ pagename }) => {
  return (

    <div className="container-fluid" style={{ position: 'relative', padding: 0 }}>
      <img src={backgnd} alt="Background" style={{ width: '100%', height: '650px' }} className='ab' />

      <div className="abtag">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 text-center">
          <center> <p data-aos="fade-up" className=''
            data-aos-duration="3000">LET'S TALK TO TRANSFORM YOUR BUSINESS</p></center>
        </div>
      </div>
      <div class="container">
        <div className="abtag1">
          <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12" >
            <center>
              <p data-aos="fade-up"
                data-aos-duration="3000">Start the journey of transforming your business with a 15-minute meeting</p>


            </center>
          </div>
        </div>
      </div>

    </div>
  );
};

export default WeareHere;
