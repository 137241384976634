import React, { useEffect, useState } from 'react'
import Photo1 from '../../assetsS/images/back1.png'
import Photo2 from '../../assetsS/images/back2.png'
import Photo3 from '../../assetsS/images/back3.png'
import Photo4 from '../../assetsS/images/back4.png'
import Box from './BoxwithIcon'
import BackgroundComponent from './BackgroundwithColorBoxes'
import Textcomponent from './Text'
import Textwithimagecomponent from './Textonimage'
import Specification from './SarasuSpecification'
import Text1Component from './Text1'
import Stage from './Stage'
import './SchoolPartnership.css'
import Biochamp from './BioChampion'
import Biochamp1 from './BioChampText'
import VideoComp from './Video'
import VideoComp1 from './Video1'
import Cardcomp from './Card'
import Faq from './Faq'
import { Link } from 'react-router-dom'
const Gallery = () => {
  useEffect(() => {})

  return (
    <div class="container-fluid pt-5 ">
      <div
        className="homebannerimgcomp111"
        style={{ backgroundImage: `url(${Photo2})` }}
      >
        <div class="text-overlaycomp1">
          <font className="bigtextcomp1">
            <center>Online NEET Testing Practice & Evaluation,</center>
          </font>

          <font className="colortextcomp1">
            <center>Management Software</center>
          </font>
          <div className="spaceontop">
            <font className="normaltextcomp1 ">
              <center>
                We provide customized disciplined testing practice align with
                the school time schedule for
              </center>
            </font>
            <font className="normaltextwithboldcomp1">
              <center>Physics, Chemistry & Biology.</center>
            </font>
          </div>
          <div className="spaceontop">
            <font className="smalltextcomp1">
              <center>
                SARASU NEET Online Testing Practice software has a wide-range of
                test practice, evaluation and training management
              </center>
            </font>
            <font className="smalltextcomp1">
              <center>
                platform that helps students to excel the accuracy, speed and
                tackle difficult indirect question at ease
              </center>
            </font>
            <div class=" row pt-3">
              <div class="col-0 col-sm-0 col-md-0 col-lg-2 col-xl-2"></div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <center>
                  {/* <Link to="/SchoolEnquiry">
                    <button class="btn" className="freeusertrail1z">
                      Request Free Demo
                    </button>
                  </Link> */}
                </center>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <center>
                  {/* <Link to="/SchoolAdminLogin">
                    <button class="btn" className="adminloginz">
                      School Admin Login
                    </button>
                  </Link> */}
                </center>
              </div>
              <div class="col-0 col-sm-0 col-md-0 col-lg-2 col-xl-2"></div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div class="">
        <Box />
      </div>
      <div class="pb-5">
        <BackgroundComponent />
      </div>
      <div class="pb-5">
        <Textcomponent />
      </div>
      <div class="pb-5">
        <Textwithimagecomponent />
      </div>

      {/* <div class="pb-5">
        <VideoComp1 />
      </div> */}
      <div class="">
        <Specification />
      </div>

      <div className="lightgrayback pb-5">
        <Text1Component />
        <Stage />
      </div>
      <div>
        <Biochamp />
      </div>
      <div>
        <Biochamp1 />
      </div>
      <div>
        <VideoComp />
      </div>
      <div>
        <Cardcomp />
      </div>
      <div>
        <Faq />
      </div>
      {/*  */}
    </div>
  )
}

export default Gallery
